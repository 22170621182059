import axios from "axios";

const API_URL = "/api/permissions/";

// Get users
const getPermissions = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL, config);
  return response.data;
};

// Get user
const getPermission = async (permissionId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + permissionId, config);
  return response.data;
};


const permissionsService = {
    getPermissions,
    getPermission
}
export default permissionsService;