import React, { useState, useEffect } from 'react';
import {Table, Button, Modal, Form, Badge} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers, reset as userReset, deleteUser, updateUser } from "../../../../app/slices/users/usersSlice";
import { getRoles, reset as roleReset,} from "../../../../app/slices/roles/rolesSlice";
import { getProjects } from "../../../../app/slices/projects/projectsSlice";
import Spinner from '../../../../components/Spinner';
import usePermissions from '../../../../hooks/usePermissions';
import CreateUser from "./create/CreateUser";

const Users = () => {
  const dispatch = useDispatch();
  const { users, isLoading, isError, message } = useSelector(state => state.users);
  const { roles } = useSelector(state => state.roles);
  const { projects } = useSelector(state => state.projects);
  const me = useSelector((state) => state.auth.user);
  const canCreateUsers = usePermissions(['users:create']);
  const canDeleteUsers = usePermissions(['users:delete']);
  const canUpdateUsers = usePermissions(['users:update']);
  const canReadRoles = usePermissions(['roles:read']);
  const canReadProjects = usePermissions(['projects:read']);

  const [showModal, setShowModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role: '',
    projects: [],
    password: '',
    userId: '',
  });


  useEffect(() => {
    if (canReadRoles) {
      dispatch(getRoles());
    }
    if (canReadProjects) {
      dispatch(getProjects());
    }
    dispatch(getUsers());

    return () => {
      dispatch(userReset());
      if (canReadRoles) {
        dispatch(roleReset());
      }
    };
  }, [dispatch, canReadRoles, canReadProjects]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message]);

  const handleDelete = async (userId) => {
    try {
      await dispatch(deleteUser(userId));
      setShowModal(false);
      await dispatch(getUsers());
      toast.success('User deleted');
    } catch (error) {
      toast.error('Error deleting user');
    }
  };

  const openEditModal = (_user) => {
    setUserToEdit(_user);
    setUserToDelete(null);
    const _formData = {
      name: _user.name,
      email: _user.email,
      role: _user.role,
      userId: _user._id,
      projects: _user.projects
    }
    setFormData(_formData);
    setShowModal(true);
  };

  const openDeleteModal = (_user) => {
    setUserToDelete(_user);
    setUserToEdit(null);
    setShowModal(true);
  };

  const handleEditSubmit = async () => {
    try {
      formData.projects = formData.projects.map((_project) => _project._id);
      await dispatch(updateUser(formData));
      setShowModal(false);
      await dispatch(getUsers());
      toast.success('User edited');
    } catch (error) {
      toast.error('Error editing user');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleProjectChange = async (e) => {
    e.preventDefault();
    const projectToFind = e.target.value;
    const userHasProject = formData.projects.some(_p => _p._id === projectToFind);

    if (userHasProject === false) {
      const projectToAdd = projects.find((project) => project._id === projectToFind);
      formData.projects = [...new Set([...formData.projects, projectToAdd])];
      setFormData(formData);
    } else {
      formData.projects = formData.projects.filter((_proj) => _proj._id !== projectToFind);
      setFormData(formData);
    }

    await dispatch(getProjects());
  }

  return (
    <>
      <h2 className='mb-5'>
        Users Management
        &nbsp;{ canCreateUsers && <CreateUser />}
      </h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th className="d-none d-lg-table-cell">Email</th>
            { canReadRoles && <th>Role</th>}
            { canReadProjects && <th className="d-none d-lg-table-cell">Projects</th>}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="3"><Spinner /></td>
            </tr>
          ) : (
            users.map((_user) => (
              <tr key={_user._id}>
                <td>{_user.name}</td>
                <td className="d-none d-lg-table-cell">{_user.email}</td>
                { canReadRoles && <td title={_user.role?.description}>{_user.role?.name}</td> }
                <td className="d-none d-lg-table-cell">{_user.projects?.map((_project) => <Badge key={_project._id} bg="dark">{_project.name}</Badge>)}</td>
                <td>
                  {
                    canUpdateUsers && (
                          <Button
                              variant="primary"
                              size="sm"
                              onClick={() => openEditModal(_user)}
                              className="me-2"
                          >
                            Edit
                          </Button>
                      )
                  }
                  {
                    canDeleteUsers && me._id !== _user._id &&
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => openDeleteModal(_user)}
                    >
                      Delete
                    </Button>
                  }
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Edit and Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{userToEdit ? 'Edit User' : 'Confirm Delete'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userToEdit && (
            <Form>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    placeholder="Leave empty to keep password unchanged"
                    value={formData.password}
                    onChange={handleInputChange}
                />
              </Form.Group>
              {
                canReadRoles && canUpdateUsers && (
                  <Form.Group controlId="role">
                    <Form.Label>Role</Form.Label>
                    <Form.Select name="role" defaultValue={roles[0]._id} onChange={handleInputChange} aria-label="roles select">
                      {
                        roles.map((role) => <option key={role._id} value={role._id}>{role.name}</option>)
                      }
                    </Form.Select>
                  </Form.Group>
                )
              }
              {
                canReadProjects && (
                  <Form.Group controlId="projects">
                    <Form.Label>Projects</Form.Label>
                    {projects.map((_project) => (
                      <Form.Check
                        id={_project._id}
                        key={_project._id}
                        type="checkbox"
                        label={_project.name}
                        value={_project._id}
                        checked={formData.projects.some((_fdp) => _fdp._id === _project._id)}
                        onChange={handleProjectChange}
                      />
                    ))}
                  </Form.Group>
                )
              }
            </Form>
          )}
          { userToDelete && (
            "Are you sure you want to delete this user? This action cannot be undone."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {userToEdit && (
            <Button variant="primary" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          )}
          { userToDelete && (
            <Button variant="danger" onClick={() => handleDelete(userToDelete._id)}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Users;
