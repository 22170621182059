import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { createProject, getProjects } from "../../../../../app/slices/projects/projectsSlice";
import {FaUserPlus} from "react-icons/fa";

const CreateProject = () => {
    const dispatch = useDispatch();
    const { isError, message } = useSelector(state => state.roles);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: ''
    });

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }
    }, [isError, message]);

    const openCreateModal = () => {
        const _formData = {
            name: '',
            description: ''
        }
        setFormData(_formData);
        setShowCreateModal(true);
    };

    const handleCreateSubmit = async () => {
        try {
            await dispatch(createProject(formData));
            setShowCreateModal(false);
            await dispatch(getProjects());
        } catch (error) {
            toast.error('Error creating project');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    return (
        <>
            <Button variant="info" onClick={openCreateModal}><FaUserPlus /> Add Project</Button>
            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required={true}
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required={true}
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCreateSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateProject;
