import axios, {AxiosError} from "axios";

const errorManager = (err: Error | AxiosError) => {
    let message;
    if (axios.isAxiosError(err)) {
        message = err.response.data.error;
    } else {
        message = (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            'An unexpected error occurred';
    }

    return message;
};

export default errorManager;