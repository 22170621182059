import React from 'react';
import { Outlet } from "react-router-dom";
import usePermissions from '../../hooks/usePermissions';

const PermissionRouter = ({children, perm}) => {
  const hasPermission = usePermissions(perm);

  return hasPermission ? (children ? children : <Outlet />) : <h2>Unauthorized</h2>;
};

export default PermissionRouter;
