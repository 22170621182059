import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects, reset, deleteProject, updateProject } from "../../../../app/slices/projects/projectsSlice";
import Spinner from '../../../../components/Spinner';
import usePermissions from '../../../../hooks/usePermissions';
import CreateProject from "./create/CreateProject";

const Projects = () => {
  const dispatch = useDispatch();
  const { projects, isLoading, isError, message } = useSelector(state => state.projects);
  const canCreateProjects = usePermissions(['projects:create']);
  const canDeleteProjects = usePermissions(['projects:delete']);
  const canUpdateProjects = usePermissions(['projects:update']);

  const [showModal, setShowModal] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState(null);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    projectId: ''
  });


  useEffect(() => {
    dispatch(getProjects());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message]);

  const handleDelete = async (_projectId) => {
    try {
      await dispatch(deleteProject(_projectId));
      setShowModal(false);
      await dispatch(getProjects());
    } catch (error) {
      toast.error('Error deleting project');
    }
  };

  const openEditModal = (_project) => {
    setProjectToEdit(_project);
    setProjectToDelete(null);
    const _formData = {
      name: _project.name,
      description: _project.description,
      projectId: _project._id
    }
    setFormData(_formData);
    setShowModal(true);
  };

  const openDeleteModal = (_project) => {
    setProjectToDelete(_project);
    setProjectToEdit(null);
    setShowModal(true);
  };

  const handleEditSubmit = async () => {
    try {
      await dispatch(updateProject(formData));
      setShowModal(false);
      await dispatch(getProjects());
    } catch (error) {
      toast.error('Error editing project');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <h2 className='mb-5'>
        Project Management
        &nbsp;{ canCreateProjects && <CreateProject />}
      </h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="3"><Spinner /></td>
            </tr>
          ) : (
            projects.map((_project) => (
              <tr key={_project._id}>
                <td>{_project.name}</td>
                <td>{_project.description}</td>
                <td>
                  {
                    canUpdateProjects && (
                          <Button
                              variant="primary"
                              size="sm"
                              onClick={() => openEditModal(_project)}
                              className="me-2"
                          >
                            Edit
                          </Button>
                      )
                  }
                  {
                    canDeleteProjects &&
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => openDeleteModal(_project)}
                    >
                      Delete
                    </Button>
                  }
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Edit and Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{projectToEdit ? 'Edit Project' : 'Confirm Delete'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {projectToEdit && (
            <Form>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Form>
          )}
          { projectToDelete && (
            "Are you sure you want to delete this project? This action cannot be undone."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {projectToEdit && (
            <Button variant="primary" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          )}
          { projectToDelete && (
            <Button variant="danger" onClick={() => handleDelete(projectToDelete._id)}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Projects;
