import axios from "axios";

const API_URL = "/api/tickets/";

// Create a new ticket
const createTicket = async (ticketData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(API_URL, ticketData, config);
  return response.data;
};

// Get tickets
const getTickets = async (projectId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let URL = API_URL;
  if (projectId) {
    URL = API_URL + projectId;
  }

  const response = await axios.get(URL, config);
  return response.data;
};

// Get user ticket
const getTicket = async (ticketId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + ticketId, config);
  return response.data;
};

// Update ticket
const updateTicket = async (ticketId, ticket, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.put(API_URL + ticketId, ticket, config);
  return response.data;
};

// Update ticket
const deleteTicket = async (ticketId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.delete(API_URL + ticketId, config);
  return response.data;
};

const ticketsService = {
  createTicket,
  getTickets,
  getTicket,
  updateTicket,
  deleteTicket
}
export default ticketsService;