import React from 'react';
import { Container } from 'react-bootstrap';

const MainContent = ({ children }) => {
  return (
    <Container fluid className="p-1">
      {children}
    </Container>
  );
};

export default MainContent;
