import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ticketsService from "./ticketsService";
import errorManager from "../../../utils/errorManager";

const initialState = {
  tickets: [],
  ticket: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create a new ticket
export const createTicket = createAsyncThunk(
  "tickets/create",
  async (ticketData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const { client, agent, project, title, description, status, department } = ticketData;
      return await ticketsService.createTicket({ client, agent, project, title, description, status, department }, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Get user ticket
export const getTickets = createAsyncThunk(
  "tickets/getAll",
  async (projectId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await ticketsService.getTickets(projectId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Get ticket
export const getTicket = createAsyncThunk(
  "tickets/get",
  async (ticketId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await ticketsService.getTicket(ticketId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// update ticket
export const updateTicket = createAsyncThunk(
  "tickets/update",
  async (ticketData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const { agent, project, title, description, status, department, notes } = ticketData;
      const { ticket } = ticketData;
      return await ticketsService.updateTicket(ticket, { agent, project, title, description, notes, status, department }, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Delete ticket
export const deleteTicket = createAsyncThunk(
    "tickets/delete",
    async (projectId, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        return await ticketsService.deleteTicket(projectId, token);
      } catch (error) {
        return thunkAPI.rejectWithValue(errorManager(error));
      }
    }
);

export const ticketsSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createTicket.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTickets.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTickets.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tickets = action.payload;
      })
      .addCase(getTickets.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getTicket.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ticket = action.payload;
      })
      .addCase(getTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateTicket.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload
      })
      .addCase(deleteTicket.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteTicket.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteTicket.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload
      })
    ;
  },
});

export const { reset } = ticketsSlice.actions;
export default ticketsSlice.reducer;
