import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers, reset as userReset, createUser } from "../../../../../app/slices/users/usersSlice";
import { getRoles, reset as roleReset, } from "../../../../../app/slices/roles/rolesSlice";
import { getProjects, reset as projectReset, } from "../../../../../app/slices/projects/projectsSlice";
import {FaUserPlus} from "react-icons/fa";

const CreateUser = () => {
    const dispatch = useDispatch();
    const { roles, isError, message } = useSelector(state => state.roles);
    const { projects } = useSelector(state => state.projects);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        role: '',
        projects: [],
        userId: '',
    });

    useEffect(() => {
        dispatch(getRoles());
        dispatch(getProjects());

        return () => {
            dispatch(userReset());
            dispatch(roleReset());
            dispatch(projectReset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }
    }, [isError, message]);

    const openCreateModal = () => {
        const _formData = {
            name: '',
            email: '',
            password: '',
            role: '',
            projects: [],
            userId: '',
        }
        setFormData(_formData);
        setShowCreateModal(true);
    };

    const handleCreateSubmit = async () => {
        try {
            await dispatch(createUser(formData));
            setShowCreateModal(false);
            await dispatch(getUsers());
        } catch (error) {
            toast.error('Error creating user');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleProjectChange = async (e) => {
        e.preventDefault();
        const projectToFind = e.target.value;
        const userHasProject = formData.projects.some(_p => _p._id === projectToFind);

        if (userHasProject === false) {
            const projectToAdd = projects.find((project) => project._id === projectToFind);
            formData.projects = [...new Set([...formData.projects, projectToAdd])];
            setFormData(formData);
        } else {
            formData.projects = formData.projects.filter((_proj) => _proj._id !== projectToFind);
            setFormData(formData);
        }

        await dispatch(getProjects());
    }

    return (
        <>
            <Button variant="info" onClick={openCreateModal}><FaUserPlus /> Add user</Button>
            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new user</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required={true}
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required={true}
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                required={true}
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="role">
                            <Form.Label>Role</Form.Label>
                            <Form.Select name="role" onChange={handleInputChange} aria-label="roles select">
                                {
                                    roles.map((role) => <option key={role._id} value={role._id}>{role.name}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="projects">
                            <Form.Label>Projects</Form.Label>
                            {projects.map((_project) => (
                                <Form.Check
                                    id={_project._id}
                                    key={_project._id}
                                    type="checkbox"
                                    label={_project.name}
                                    value={_project._id}
                                    checked={formData.projects.some((_fdp) => _fdp._id === _project._id)}
                                    onChange={handleProjectChange}
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCreateSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateUser;
