import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import {createRole, getRoles, reset} from "../../../../../app/slices/roles/rolesSlice";
import usePermissions from '../../../../../hooks/usePermissions';
import { getPermissions } from "../../../../../app/slices/permissions/permissionsSlice";
import {FaUserPlus} from "react-icons/fa";

const CreateRole = () => {
    const dispatch = useDispatch();
    const canReadPermissions = usePermissions(['permissions:read']);
    const { permissions, isError, message } = useSelector(state => state.permissions);

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        permissions: [],
    });

    useEffect(() => {
        if (canReadPermissions) {
            dispatch(getPermissions());
        }

        return () => {
            dispatch(reset());
        };
    }, [dispatch]);

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }
    }, [isError, message]);

    const toPascalCase = str => (str.match(/[a-zA-Z0-9]+/g)
        || []).map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join('');

    const openCreateModal = () => {
        const _formData = {
            name: '',
            description: '',
            permissions: []
        }
        setFormData(_formData);
        setShowCreateModal(true);
    };

    const handleCreateSubmit = async () => {
        try {
            await dispatch(createRole(formData));
            setShowCreateModal(false);
            await dispatch(getRoles());
        } catch (error) {
            toast.error('Error creating role');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handlePermissionChange = async (e) => {
        e.preventDefault();
        const permissionToFind = e.target.value;
        const roleHasPermission = formData.permissions.some(_p => _p._id === permissionToFind);

        if (roleHasPermission === false) {
            const permissionToAdd = permissions.find((permission) => permission._id === permissionToFind);
            formData.permissions = [...new Set([...formData.permissions, permissionToAdd])];
            setFormData(formData);
        } else {
            formData.permissions = formData.permissions.filter((_perm) => _perm._id !== permissionToFind);
            setFormData(formData);
        }
        await dispatch(getPermissions());
    }

    return (
        <>
            <Button variant="info" onClick={openCreateModal}><FaUserPlus /> Add role</Button>
            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                required={true}
                                type="text"
                                name="name"
                                value={toPascalCase(formData.name)}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required={true}
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="permissions">
                            <Form.Label>Permissions</Form.Label>
                            {permissions.map(permission => (
                                <Form.Check
                                    key={permission._id}
                                    type="checkbox"
                                    label={permission.name}
                                    value={permission._id}
                                    checked={formData.permissions.some((_fdp) => _fdp._id === permission._id)}
                                    onChange={handlePermissionChange}
                                />
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCreateSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateRole;
