import React, { useState, useEffect } from 'react';
import {Table, Button, Modal, Form, InputGroup} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getDepartments, reset, deleteDepartment, updateDepartment } from "../../../../app/slices/departments/departmentsSlice";
import Spinner from '../../../../components/Spinner';
import usePermissions from '../../../../hooks/usePermissions';
import CreateDepartment from "./create/CreateDepartment";

const Departments = () => {
  const dispatch = useDispatch();
  const { departments, isLoading, isError, message } = useSelector(state => state.departments);
  const canCreateDepartments = usePermissions(['departments:create']);
  const canDeleteDepartments = usePermissions(['departments:delete']);
  const canUpdateDepartments = usePermissions(['departments:update']);

  const [showModal, setShowModal] = useState(false);
  const [departmentToEdit, setDepartmentToEdit] = useState(null);
  const [departmentToDelete, setDepartmentToDelete] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    email: '',
    departmentId: '',

    imapHost: '',
    imapPort: '',
    imapPassword: '',

    smtpHost: '',
    smtpPort: '',
    smtpPassword: '',
  });


  useEffect(() => {
    dispatch(getDepartments());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message]);

  const handleDelete = async (_deptId) => {
    try {
      await dispatch(deleteDepartment(_deptId));
      setShowModal(false);
      await dispatch(getDepartments());
      toast.success('Department deleted');
    } catch (error) {
      toast.error('Error deleting department');
    }
  };

  const openEditModal = (_dept) => {
    setDepartmentToEdit(_dept);
    setDepartmentToDelete(null);
    const _formData = {
      name: _dept.name,
      description: _dept.description,
      email: _dept.email,
      departmentId: _dept._id,

      imapHost: _dept.imapHost,
      imapPort: _dept.imapPort,
      imapPassword: _dept.imapPassword,

      smtpHost: _dept.smtpHost,
      smtpPort: _dept.smtpPort,
      smtpPassword: _dept.smtpPassword,
    }
    setFormData(_formData);
    setShowModal(true);
  };

  const openDeleteModal = (_dept) => {
    setDepartmentToDelete(_dept);
    setDepartmentToEdit(null);
    setShowModal(true);
  };

  const handleEditSubmit = async () => {
    try {
      await dispatch(updateDepartment(formData));
      setShowModal(false);
      await dispatch(getDepartments());
      toast.success('Department updated');
    } catch (error) {
      toast.error('Error editing department');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <h2 className='mb-5'>
        Departments Management
        &nbsp;{ canCreateDepartments && <CreateDepartment />}
      </h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th className="d-none d-lg-table-cell">Description</th>
            <th className="d-none d-lg-table-cell">Email of the department</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="4"><Spinner /></td>
            </tr>
          ) : (
            departments.map((_dept) => (
              <tr key={_dept._id}>
                <td>{_dept.name}</td>
                <td className="d-none d-lg-table-cell">{_dept.description}</td>
                <td className="d-none d-lg-table-cell">{_dept.email}</td>
                <td>
                  {
                    canUpdateDepartments && (
                          <Button
                              variant="primary"
                              size="sm"
                              onClick={() => openEditModal(_dept)}
                              className="me-2"
                          >
                            Edit
                          </Button>
                      )
                  }
                  {
                    canDeleteDepartments &&
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => openDeleteModal(_dept)}
                    >
                      Delete
                    </Button>
                  }
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Edit and Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{departmentToEdit ? 'Edit Department' : 'Confirm Delete'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {departmentToEdit && (
            <Form>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    required={true}
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    required={true}
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>Email of the department</Form.Label>
                <Form.Control
                    required={true}
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                />
              </Form.Group>
              <h6 className="mt-3">Mail settings</h6>
              <InputGroup className="mt-1">
                <Form.Control
                    required={true}
                    type="url"
                    name="smtpHost"
                    value={formData.smtpHost}
                    onChange={handleInputChange}
                    placeholder="SMTP: host"
                    aria-label="Smtp host"
                />
                <Form.Control
                    required={true}
                    type="url"
                    name="imapHost"
                    value={formData.imapHost}
                    onChange={handleInputChange}
                    placeholder="IMAP: host"
                    aria-label="Imap host"
                />
              </InputGroup>
              <InputGroup className="mt-1">
                <Form.Control
                    required={true}
                    type="password"
                    name="smtpPassword"
                    value={formData.smtpPassword}
                    onChange={handleInputChange}
                    placeholder="SMTP: password"
                    aria-label="Smtp password"
                />
                <Form.Control
                    required={true}
                    type="password"
                    name="imapPassword"
                    value={formData.imapPassword}
                    onChange={handleInputChange}
                    placeholder="IMAP: password"
                    aria-label="Imap password"
                />
              </InputGroup>
              <InputGroup className="mt-1">
                <Form.Control
                    required={true}
                    type="number"
                    name="smtpPort"
                    value={formData.smtpPort}
                    onChange={handleInputChange}
                    placeholder="SMTP: port"
                    aria-label="Smtp port"
                />
                <Form.Control
                    required={true}
                    type="number"
                    name="imapPort"
                    value={formData.imapPort}
                    onChange={handleInputChange}
                    placeholder="IMAP: port"
                    aria-label="Imap port"
                />
              </InputGroup>
            </Form>
          )}
          { departmentToDelete && (
            "Are you sure you want to delete this department? This action cannot be undone."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {departmentToEdit && (
            <Button variant="primary" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          )}
          { departmentToDelete && (
            <Button variant="danger" onClick={() => handleDelete(departmentToDelete._id)}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Departments;
