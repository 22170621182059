import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects, reset } from "../../../../app/slices/projects/projectsSlice";
import { getDepartments } from "../../../../app/slices/departments/departmentsSlice";
import {createTicket, getTickets} from "../../../../app/slices/tickets/ticketsSlice";
import usePermissions from '../../../../hooks/usePermissions';
import {FaUserPlus} from "react-icons/fa";
import * as yup from 'yup';

const CreateTicket = () => {
    const dispatch = useDispatch();
    const { projects, isError, message } = useSelector(state => state.projects);
    const { isError: isTicketError, message: messageTicket } = useSelector(state => state.tickets);
    const { departments, isError: isDeptError, message: messageDept } = useSelector(state => state.departments);
    const user = useSelector((state) => state.auth.user);
    const canReadProjects = usePermissions(['projects:read']);
    const canReadDepartments = usePermissions(['departments:read']);

    const error = isError || isTicketError || isDeptError;
    const errorMessage = message || messageTicket || messageDept;

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        project: '',
        department: '',
        client: '',
        agent: user._id,
        status: 'new'
    });

    let yupSchema = yup.object().shape({
        client: yup.string().email(),
        agent: yup.string().required(),
        title: yup.string().required(),
        description: yup.string().required(),
        project: yup.string().required(),
        department: yup.string().required(),
    });

    useEffect(() => {
        if (canReadProjects && canReadDepartments) {
            dispatch(getProjects());
            dispatch(getDepartments());
        }

        return () => {
            dispatch(reset())
        };
    }, [dispatch, canReadProjects, canReadDepartments]);
    useEffect(() => {
        if (error) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    const openCreateModal = () => {
        const _formData = {
            title: '',
            description: '',
            project: '',
            department: '',
            agent: user._id,
            client: '',
            status: 'new'
        };
        setFormData(_formData);
        setShowCreateModal(true);
    };

    const handleCreateSubmit = async () => {
        try {
            yupSchema.validate(formData, {abortEarly: false})
                .then(async (validatedData) => {
                    await dispatch(createTicket(validatedData));
                    setShowCreateModal(false);
                    await dispatch(getTickets());
                    toast.success('Ticket created');
                })
                .catch((err) => {
                    const errorMessage = err.errors.join('\n');
                    toast.error(errorMessage);
                });
        } catch (error) {
            toast.error('Error creating ticket');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    };

    return (
        <>
            <Button variant="info" onClick={openCreateModal}><FaUserPlus /> Add ticket</Button>
            <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create a new ticket</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="client">
                            <Form.Label>Client email</Form.Label>
                            <Form.Control
                                required={true}
                                type="email"
                                name="client"
                                value={formData.client}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="title">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                required={true}
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                required={true}
                                as="textarea"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                style={{ height: '100px' }}
                                placeholder="Enter the ticket description"
                            />
                        </Form.Group>
                        <Form.Group controlId="project">
                            <Form.Label>Project</Form.Label>
                            <Form.Select required={false} name="project" onChange={handleInputChange}>
                                <option>Select the related project</option>
                                {
                                    projects.map((_project) => <option key={_project._id} value={_project._id}>{_project.name}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="department">
                            <Form.Label>Department</Form.Label>
                            <Form.Select required={true} name="department" onChange={handleInputChange}>
                                <option>A dedicated department</option>
                                {
                                    departments.map((_department) => <option key={_department._id} value={_department._id}>{_department.name}</option>)
                                }
                            </Form.Select>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleCreateSubmit}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CreateTicket;
