import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth/authSlice';
import usersReducer from './slices/users/usersSlice';
import rolesReducer from './slices/roles/rolesSlice';
import permissionsReducer from './slices/permissions/permissionsSlice';
import departmentsReducer from './slices/departments/departmentsSlice';
import projectsReducer from './slices/projects/projectsSlice';
import ticketsReducer from './slices/tickets/ticketsSlice';
import notesReducer from './slices/notes/notesSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: usersReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    departments: departmentsReducer,
    projects: projectsReducer,
    tickets: ticketsReducer,
    notes: notesReducer,
  },
});
