import React, { useState, useEffect } from 'react';
import {Table, Button, Modal, Form, Badge, Container, Row, Col} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects } from "../../../app/slices/projects/projectsSlice";
import { getDepartments } from "../../../app/slices/departments/departmentsSlice";
import { getTickets, reset, deleteTicket, updateTicket } from "../../../app/slices/tickets/ticketsSlice";
import { createNote } from "../../../app/slices/notes/notesSlice";
import Spinner from '../../../components/Spinner';
import usePermissions from '../../../hooks/usePermissions';
import CreateTicket from "./create/CreateTicket";

const Tickets = () => {
  const dispatch = useDispatch();
  const { tickets, isLoading, isError, message } = useSelector(state => state.tickets);
  const { projects } = useSelector(state => state.projects);
  const { departments } = useSelector(state => state.departments);
  const me = useSelector((state) => state.auth.user);
  const canReadProjects = usePermissions(['projects:read']);
  const canReadNotes = usePermissions(['notes:read']);
  const canUpdateNotes = usePermissions(['notes:update']);
  const canReadDepartments = usePermissions(['departments:read']);
  const canCreateTickets = usePermissions(['tickets:create']);
  const canUpdateTickets = usePermissions(['tickets:update']);
  const canDeleteTickets = usePermissions(['tickets:delete']);

  const [showModal, setShowModal] = useState(false);
  const [ticketToEdit, setTicketToEdit] = useState(null);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const [formData, setFormData] = useState({
    client: '',
    agent: '',
    title: '',
    description: '',
    project: '',
    department: '',
    status: '',
    notes: '',
    note: '',
    ticket: '',
  });


  useEffect(() => {
    if (canCreateTickets && canReadProjects && canReadDepartments) {
      dispatch(getProjects());
      dispatch(getTickets());
      dispatch(getDepartments());
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, canCreateTickets, canReadProjects, canReadDepartments]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message]);

  const handleDelete = async (ticketId) => {
    try {
      await dispatch(deleteTicket(ticketId));
      setShowModal(false);
      await dispatch(getTickets());
    } catch (error) {
      toast.error('Error deleting ticket');
    }
  };

  const openEditModal = async (_ticket) => {
    setTicketToEdit(_ticket);
    setTicketToDelete(null);
    const _formData = {
      client: _ticket.client,
      agent: me._id,
      title: _ticket.title,
      description: _ticket.description,
      project: _ticket.project,
      department: _ticket.department,
      status: _ticket.status,
      notes: _ticket.notes,
      note: '',
      ticket: _ticket._id,
    };
    setFormData(_formData);
    setShowModal(true);
  };

  const openDeleteModal = (_ticket) => {
    setTicketToDelete(_ticket);
    setTicketToEdit(null);
    setShowModal(true);
  };

  const handleEditSubmit = async () => {
    try {
      formData.notes = formData.notes?.map((_note) => _note._id);
      if (formData.note.trim() !== '') {
        const noteData = {
          ticket: formData.ticket,
          description: formData.note,
          user: me._id,
        }
        const note = await dispatch(createNote(noteData));
        if (!note.payload) {
          throw new Error('Error creating note');
        }
        formData.notes = [...new Set([...formData.notes, note.payload._id])];
        setFormData(formData);
      }
      const updateData = {
        ticket: formData.ticket,
        user: formData.user._id,
        project: formData.project,
        title: formData.title,
        description: formData.description,
        notes: formData.notes,
        status: formData.status,
        department: formData.department,
      };
      await dispatch(updateTicket(updateData));
      setShowModal(false);
      await dispatch(getTickets());
    } catch (error) {
      setShowModal(false);
      toast.error('Error editing ticket');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <h2 className='mb-5'>
        Tickets Management
        &nbsp;{ canCreateTickets && <CreateTicket />}
      </h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Client</th>
            <th className="d-none d-lg-table-cell">Agent</th>
            <th className="d-none d-lg-table-cell">Department</th>
            <th>Title</th>
            <th>Status</th>
            <th className="d-none d-lg-table-cell">Notes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="7"><Spinner /></td>
            </tr>
          ) : (
            tickets?.map((_ticket) => (
              <tr key={_ticket._id}>
                <td>{_ticket.client.name}</td>
                <td className="d-none d-lg-table-cell">{_ticket.agent?.name}</td>
                <td className="d-none d-lg-table-cell">{_ticket.department?.name}</td>
                <td>{_ticket.title}</td>
                <td>{
                  _ticket.status === 'new'
                      ? (<Badge pill bg="primary">{_ticket.status}</Badge>)
                      : (
                          _ticket.status === 'opened'
                              ? (<Badge pill bg="info">{_ticket.status}</Badge>)
                              : (
                                  _ticket.status === 'solved'
                                      ? (<Badge pill bg="success">{_ticket.status}</Badge>)
                                      : (
                                          _ticket.status === 'unsolved'
                                            ? (<Badge pill bg="secondary">{_ticket.status}</Badge>)
                                            : <></>
                                      )
                              )
                      )
                }</td>
                <td className="d-none d-lg-table-cell">{_ticket.notes.length}</td>
                <td>
                  {
                    canUpdateTickets && (
                          <Button
                              variant="primary"
                              size="sm"
                              onClick={() => openEditModal(_ticket)}
                              className="me-2"
                          >
                            Edit
                          </Button>
                      )
                  }
                  {
                    canDeleteTickets &&
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => openDeleteModal(_ticket)}
                    >
                      Delete
                    </Button>
                  }
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Edit and Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered fullscreen={!!ticketToEdit}>
        <Modal.Header closeButton>
          <Modal.Title>{ticketToEdit ? 'Edit Ticket' : 'Confirm Delete'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row md={12}>
              <Col xs={12} md={4}>
                {ticketToEdit && (
                    <Form>
                      <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            name="title"
                            value={formData.title}
                            // onChange={handleInputChange}
                            disabled={true}
                        />
                      </Form.Group>
                      <Form.Group controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            style={{ height: '100px' }}
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                        />
                      </Form.Group>
                      <Form.Group controlId="project">
                        <Form.Label>Project</Form.Label>
                        <Form.Select name="project" onChange={handleInputChange}>
                          <option>Select the target project</option>
                          {
                            projects.map((_project) => <option key={_project._id} value={_project._id}>{_project.name}</option>)
                          }
                        </Form.Select>
                      </Form.Group>
                      <Form.Group controlId="department">
                        <Form.Label>Department</Form.Label>
                        <Form.Select name="department" onChange={handleInputChange}>
                          <option>Select a department</option>
                          {
                            departments.map((_depart) => <option key={_depart._id} value={_depart._id}>{_depart.name}</option>)
                          }
                        </Form.Select>
                      </Form.Group>
                      <Form.Group controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Select name="status" defaultValue={formData.status === 'new'? 'new' : formData.status} onChange={handleInputChange}>
                          <option value="new">New</option>
                          <option value="opened">Opened</option>
                          <option value="solved">Solved</option>
                          <option value="unsolved">Unsolved</option>
                        </Form.Select>
                      </Form.Group>
                    </Form>
                )}
                { ticketToDelete && (
                    "Are you sure you want to delete this ticket? This action cannot be undone."
                )}
              </Col>
              <Col xs={12} md={8}>
                {ticketToEdit && canReadNotes && canUpdateNotes && (
                    <>
                      <h4>Notes</h4>
                      <Form>
                        <Form.Group controlId="note">
                          {/*<Form.Label>Note</Form.Label>*/}
                          <Form.Control
                              as="textarea"
                              style={{ height: '100px' }}
                              name="note"
                              placeholder="Add new note on this ticket"
                              value={formData.note}
                              onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Form>
                      <br />
                      <Table striped bordered hover responsive>
                        <thead>
                          <tr>
                            <th width="150px">Updated on</th>
                            <th width="200px">By</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody style={{height: '46vh', overflowY: 'scroll'}}>
                        {
                          formData.notes?.map((_n, key) => (
                              <tr key={key}>
                                <td>{(new Date(_n.createdAt)).toUTCString()}</td>
                                <td>{_n.user?.name + ' - ' + _n.user?.email}</td>
                                <td>{_n.description}</td>
                              </tr>
                          ))
                        }
                        </tbody>
                      </Table>
                    </>)
                }
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {ticketToEdit && (
            <Button variant="primary" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          )}
          { ticketToDelete && (
            <Button variant="danger" onClick={() => handleDelete(ticketToDelete._id)}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Tickets;
