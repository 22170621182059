import React from 'react';
import { Card } from 'react-bootstrap';

const StatisticsCard = ({ icon, value, label, iconColor }) => {
    return (
        <Card className="bg-dark text-light">
            <Card.Body className="d-flex align-items-center">
                <div className={`me-3 display-6 text-${iconColor}`}>
                    {icon}
                </div>
                <div>
                    <h4>{value}</h4>
                    <p className="mb-0">{label}</p>
                </div>
            </Card.Body>
        </Card>
    );
};

export default StatisticsCard;
