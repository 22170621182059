import React, {useEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DashboardHeader from './DashboardHeader';
import StatisticsCard from './StatisticsCard';
import { FaUser, FaUsers, FaBriefcase, FaProjectDiagram, FaTrophy, FaExclamationTriangle, FaClipboardList, FaCheckCircle } from 'react-icons/fa';
import {useDispatch, useSelector} from "react-redux";

import '../styles/dashboard.css';
import {getUsers} from "../../../app/slices/users/usersSlice";
import {getDepartments} from "../../../app/slices/departments/departmentsSlice";
import {getProjects} from "../../../app/slices/projects/projectsSlice";
import {getTickets} from "../../../app/slices/tickets/ticketsSlice";
import {toast} from "react-toastify";
const Dashboard = () => {
    const dispatch = useDispatch();
    const { users, isLoading, isError, message } = useSelector(state => state.users);
    const { departments, isError: isErrorDept, message: messageDept } = useSelector(state => state.departments);
    const { projects, isError: isErrorProject, message: messageProject } = useSelector(state => state.projects);
    const { tickets, isError: isErrorTicket, message: messageTicket } = useSelector(state => state.tickets);

    const error = isError || isErrorDept || isErrorProject || isErrorTicket;
    const errorMessage = message || messageTicket || messageDept || messageProject;

    useEffect(() => {
        dispatch(getUsers());
        dispatch(getDepartments());
        dispatch(getProjects());
        dispatch(getTickets());
    }, [dispatch]);

    useEffect(() => {
        if (error) {
            toast.error(errorMessage);
        }
    }, [error, errorMessage]);

    const dashboardData = {
        users,
        agents: users.filter((_u) => _u.role?.name !== 'Customer'),
        customers: users.filter((_u) => _u.role?.name === 'Customer'),
        departments,
        projects,
        tickets: {
            total: tickets.length,
            new: tickets.filter((_t) => _t.status === 'new'),
            opened: tickets.filter((_t) => _t.status === 'opened'),
            unsolved: tickets.filter((_t) => _t.status === 'unsolved'),
            solved: tickets.filter((_t) => _t.status === 'solved'),
        },
    };


    return (
        <Container fluid className="bg-dark text-light p-4" style={{ minHeight: '100vh' }}>
            {!isLoading && (
                <DashboardHeader
                    users={dashboardData.users}
                    tickets={dashboardData.tickets}
                />
            )}
            <Row className="mt-4">
                <Col md={3}><StatisticsCard icon={<FaUser />} value={dashboardData.agents.length} label="Agents" iconColor="success" /></Col>
                <Col md={3}><StatisticsCard icon={<FaUsers />} value={dashboardData.customers.length} label="Customers" iconColor="primary" /></Col>
                <Col md={3}><StatisticsCard icon={<FaProjectDiagram />} value={dashboardData.departments.length} label="Departments" iconColor="warning" /></Col>
                <Col md={3}><StatisticsCard icon={<FaBriefcase />} value={dashboardData.projects.length} label="Projects" iconColor="info" /></Col>
            </Row>
            <Row className="mt-4">
                <Col md={3}><StatisticsCard icon={<FaTrophy />} value={dashboardData.tickets.new.length} label="New Tickets" iconColor="primary" /></Col>
                <Col md={3}><StatisticsCard icon={<FaExclamationTriangle />} value={dashboardData.tickets.opened.length} label="Opened" iconColor="info" /></Col>
                <Col md={3}><StatisticsCard icon={<FaClipboardList />} value={dashboardData.tickets.unsolved.length} label="Unsolved" iconColor="secondary" /></Col>
                <Col md={3}><StatisticsCard icon={<FaCheckCircle />} value={dashboardData.tickets.solved.length} label="Solved" iconColor="success" /></Col>
            </Row>
            <Row className="mt-4">
                <Col md={6}>
                    {/*<PieChartCard title="Sales Distribution" data={pieData} labels={pieLabels} />*/}
                </Col>
                <Col md={3}>
                    {/*<TicketsSolvedCard data={ticketData} />*/}
                </Col>
                <Col md={3}>
                    {/*<TicketsStatusCard data={statusData} />*/}
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
