import axios from 'axios'

const API_URL = '/api/users'

// Register user
const register = async (userData) =>{
    const response = await axios.post(API_URL, userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}

// Login user
const login = async (userData) =>{
    const response = await axios.post(API_URL + '/login', userData)

    if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
    }
    return response.data
}

// Logout
const logout = async (user)=> {
    const response = await axios.post(API_URL + '/logout', user)

    if (response.data) {
        localStorage.removeItem('user');
    }

    return response.data;
}

const authService = {
    register,
    logout,
    login
}

export default authService