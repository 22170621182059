import React from 'react';
import { Container, Row, Col, Badge } from 'react-bootstrap';

const DashboardHeader = ({users, tickets}) => {
    return (
        <Container fluid className="py-2 bg-dark text-light">
            <Row className="justify-content-center">
                <Col className="text-center">
                    <Badge pill bg="warning" text="dark">Agents & Cust: {users.length}</Badge>
                </Col>
                <Col className="text-center">
                    <Badge pill bg="danger">Tickets: {tickets.total}</Badge>
                </Col>
                <Col className="text-center">
                    <Badge pill bg="primary">New Tickets: {tickets.new.length}</Badge>
                </Col>
                <Col className="text-center">
                    <Badge pill bg="info">Opened Tickets: {tickets.opened.length}</Badge>
                </Col>
                <Col className="text-center">
                    <Badge pill bg="success">Solved Tickets: {tickets.solved.length}</Badge>
                </Col>
                <Col className="text-center">
                    <Badge pill bg="secondary">Unsolved Tickets:  {tickets.unsolved.length}</Badge>
                </Col>
            </Row>
        </Container>
    );
};

export default DashboardHeader;
