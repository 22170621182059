import React from 'react';
import { Container } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="bg-light text-center py-3 mt-auto">
      <Container>
        <span>© {new Date().getFullYear()} by Devminds Tech. All rights reserved.</span>
      </Container>
    </footer>
  );
};

export default Footer;
