import React, { useState } from 'react';
import { Nav, Accordion, Card, useAccordionButton } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import {FaHome, FaCog, FaChevronDown, FaChevronRight, FaBriefcase, FaProjectDiagram} from 'react-icons/fa';
import './styles/sidebar.css';
import usePermissions from '../../hooks/usePermissions';
import {FaTicket, FaUsersGear, FaSquarespace} from 'react-icons/fa6';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const canAccessSettings = usePermissions(['settings:access']);
  const canReadUsers = usePermissions(['users:read']);
  const canReadRoles = usePermissions(['roles:read']);
  const canReadDepartments = usePermissions(['departments:read']);
  const canReadProjects = usePermissions(['projects:read']);
  const canReadTickets = usePermissions(['tickets:read']);

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));

    return (
        <div onClick={decoratedOnClick} style={{ cursor: 'pointer' }}>
          {children}
        </div>
    );
  };

  return (
      <div className="d-flex flex-column vh-100 w-30 bg-dark p-1 sidebar">
        <h4 className="sidebar-heading text-white">Vacation Rentals</h4>
        <hr />
        <Nav defaultActiveKey="/" className="flex-column">
          <Nav.Link as={NavLink} to="/" className="text-white sidebar-link">
            <FaHome className="icon" /> <span className="link-text">Dashboard</span>
          </Nav.Link>
          {canReadTickets && (
              <Nav.Link as={NavLink} to="/tickets" className="text-white sidebar-link">
                <FaTicket className="icon" /> <span className="link-text">Tickets</span>
              </Nav.Link>
          )}
          {canAccessSettings && (
              <Accordion>
                <Card className="bg-dark border-0">
                  <Card.Header className="p-0">
                    <CustomToggle eventKey="0">
                      <Nav.Link className="text-white sidebar-link">
                        <FaCog className="icon" /> <span className="link-text">Settings</span>{' '}
                        {isOpen ? <FaChevronDown style={{ float: 'right' }} /> : <FaChevronRight style={{ float: 'right' }} />}
                      </Nav.Link>
                    </CustomToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="pt-1 pl-2 bg-secondary rounded">
                      {canReadProjects && (
                          <Nav.Link as={NavLink} to="/settings/projects" className="text-white pl-2 sidebar-link">
                            <FaBriefcase className="icon" /> <span className="link-text">Projects</span>
                          </Nav.Link>
                      )}
                      {canReadDepartments && (
                          <Nav.Link as={NavLink} to="/settings/departments" className="text-white pl-4 sidebar-link">
                            <FaProjectDiagram className="icon" /> <span className="link-text">Departments</span>
                          </Nav.Link>
                      )}
                      {canReadUsers && (
                          <Nav.Link as={NavLink} to="/settings/users" className="text-white pl-4 sidebar-link">
                            <FaUsersGear className="icon" /> <span className="link-text">Users</span>
                          </Nav.Link>
                      )}
                      {canReadRoles && (
                          <Nav.Link as={NavLink} to="/settings/roles" className="text-white pl-4 sidebar-link">
                            <FaSquarespace className="icon" /> <span className="link-text">Roles</span>
                          </Nav.Link>
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
          )}
        </Nav>
      </div>
  );
};

export default Sidebar;
