import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import projectsService from "./projectsService";
import errorManager from "../../../utils/errorManager";

const initialState = {
  projects: [],
  project: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get projects
export const getProjects = createAsyncThunk(
  "projects/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectsService.getProjects(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Get project
export const getProject = createAsyncThunk(
  "projects/getOne",
  async (projectId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectsService.getProject(projectId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Delete project
export const deleteProject = createAsyncThunk(
  "projects/deleteOne",
  async (projectId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await projectsService.deleteProject(projectId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Update project
export const updateProject = createAsyncThunk(
  "projects/updateOne",
  async (projectData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const { name, description } = projectData;
      return await projectsService.updateProject(projectData.projectId, { name, description }, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Update project
export const createProject = createAsyncThunk(
    "projects/createOne",
    async (projectData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        const { name, description } = projectData;
        return await projectsService.createProject({ name, description }, token);
      } catch (error) {
        return thunkAPI.rejectWithValue(errorManager(error));
      }
    }
);

export const projectsSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // get projects
      .addCase(getProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.projects = action.payload;
      })
      .addCase(getProjects.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // get project
      .addCase(getProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.project = action.payload;
      })
      .addCase(getProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // delete project
      .addCase(deleteProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.project = {};
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // update project
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // create project
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
    ;
  },
});

export const { reset } = projectsSlice.actions;
export default projectsSlice.reducer;
