import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import rolesService from "./rolesService";
import errorManager from "../../../utils/errorManager";

const initialState = {
  roles: [],
  role: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get roles
export const getRoles = createAsyncThunk(
  "roles/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await rolesService.getRoles(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Get role
export const getRole = createAsyncThunk(
  "roles/getOne",
  async (roleId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await rolesService.getRole(roleId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Delete role
export const deleteRole = createAsyncThunk(
    "roles/deleteOne",
    async (roleId, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.token;
            return await rolesService.deleteRole(roleId, token);
        } catch (error) {
            return thunkAPI.rejectWithValue(errorManager(error));
        }
    }
);

// create role
export const createRole = createAsyncThunk(
    "roles/createOne",
    async (roleData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        const {name, description, permissions } = roleData;
        return await rolesService.createRole({name, description, permissions }, token);
      } catch (error) {
        return thunkAPI.rejectWithValue(errorManager(error));
      }
    }
);

// Update role
export const updateRole = createAsyncThunk(
    "roles/updateOne",
    async (roleData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        const {name, description, permissions } = roleData;
        return await rolesService.updateRole(roleData.roleId, {name, description, permissions }, token);
      } catch (error) {
        return thunkAPI.rejectWithValue(errorManager(error));
      }
    }
);

export const rolesSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Get roles
      .addCase(getRoles.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.roles = action.payload;
      })
      .addCase(getRoles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get Role
      .addCase(getRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.role = action.payload;
      })
      .addCase(getRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
        // Get Role
        .addCase(deleteRole.pending, (state) => {
            state.isLoading = true;
        })
        .addCase(deleteRole.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        })
        .addCase(deleteRole.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        })
      // create role
      .addCase(createRole.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
    ;
  },
});

export const { reset } = rolesSlice.actions;
export default rolesSlice.reducer;
