import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import departmentsService from "./departmentsService";
import errorManager from "../../../utils/errorManager";

const initialState = {
  departments: [],
  department: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get departments
export const getDepartments = createAsyncThunk(
  "departments/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await departmentsService.getDepartments(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Get department
export const getDepartment = createAsyncThunk(
  "departments/getOne",
  async (departmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await departmentsService.getDepartment(departmentId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Delete department
export const deleteDepartment = createAsyncThunk(
  "departments/deleteOne",
  async (departmentId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await departmentsService.deleteDepartment(departmentId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Update department
export const updateDepartment = createAsyncThunk(
  "departments/updateOne",
  async (departmentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      const {
        name, description, email,
        imapHost, imapPort, imapPassword,
        smtpHost, smtpPort, smtpPassword
      } = departmentData;
      return await departmentsService.updateDepartment(
          departmentData.departmentId,
          {
            name,
            description,
            email,
            imapHost,
            imapPort,
            imapPassword,
            smtpHost,
            smtpPort,
            smtpPassword
          },
          token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Update department
export const createDepartment = createAsyncThunk(
    "departments/createOne",
    async (departmentData, thunkAPI) => {
      try {
        const token = thunkAPI.getState().auth.user.token;
        const {
          name, description, email,
          imapHost, imapPort, imapPassword,
          smtpHost, smtpPort, smtpPassword
        } = departmentData;
        return await departmentsService.createDepartment(
            {
              name, description, email,
              imapHost, imapPort, imapPassword,
              smtpHost, smtpPort, smtpPassword
            },
            token
        );
      } catch (error) {
        return thunkAPI.rejectWithValue(errorManager(error));
      }
    }
);

export const departmentsSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // get departments
      .addCase(getDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.departments = action.payload;
      })
      .addCase(getDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // get department
      .addCase(getDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.department = action.payload;
      })
      .addCase(getDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // delete department
      .addCase(deleteDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
        state.department = {};
      })
      .addCase(deleteDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // update department
      .addCase(updateDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(updateDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // create department
      .addCase(createDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(createDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
    ;
  },
});

export const { reset } = departmentsSlice.actions;
export default departmentsSlice.reducer;
