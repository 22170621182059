import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fragment } from "react";
import Footer from "./components/private/Footer";
import Sidebar from "./components/private/Sidebar";
import MainContent from "./components/private/MainContent";
import Header from "./components/private/Header";

import Home from "./pages/private/home/Home";
import Users from "./pages/private/settings/users/Users";

import 'bootstrap/dist/css/bootstrap.min.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import PrivateRouter from "./components/private/PrivateRouter";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import PermissionRouter from "./components/private/PermissionRouter";
import Roles from "./pages/private/settings/roles/Roles";
import Departments from "./pages/private/settings/departments/Departments";
import Projects from "./pages/private/settings/projects/Projects";
import Tickets from "./pages/private/tickets/Tickets";

function App() {
  return (
    <Fragment>
      <ThemeProvider>
        <Router>
          <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<Login />} />
            {/*<Route path="/register" element={<Register />} />*/}
            
            {/* Private Routes */}
            <Route path="*" element={<PrivateRouter />}>
              <Route
                path="*"
                element={
                  <div className="d-flex">
                    <Sidebar />
                    <div className="w-100 d-flex flex-column">
                      <Header />
                      <MainContent>
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/tickets" element={<PermissionRouter perm={['tickets:read']} />}>
                            <Route path="/tickets" element={<Tickets />} />
                          </Route>
                          <Route path="/settings" element={<PermissionRouter perm={['settings:access']} />}>
                            <Route path="/settings/users" element={<PermissionRouter perm={['users:read']} />}>
                              <Route path="/settings/users" element={<Users />} />
                            </Route>
                            <Route path="/settings/roles" element={<PermissionRouter perm={['roles:read']} />}>
                              <Route path="/settings/roles" element={<Roles />} />
                            </Route>
                            <Route path="/settings/departments" element={<PermissionRouter perm={['departments:read']} />}>
                              <Route path="/settings/departments" element={<Departments />} />
                            </Route>
                            <Route path="/settings/projects" element={<PermissionRouter perm={['projects:read']} />}>
                              <Route path="/settings/projects" element={<Projects />} />
                            </Route>
                          </Route>
                        </Routes>
                      </MainContent>
                      <Footer />
                    </div>
                  </div>
                }
              />
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </Fragment>
  );
}

export default App;