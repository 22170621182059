import { useSelector } from "react-redux";

const usePermissions = (requiredPermissions) => {
  const user = useSelector((state) => state.auth.user);

  return !user ? false : user.role.permissions.some((perm) =>
    requiredPermissions.includes(perm)
  );
}

export default usePermissions;
