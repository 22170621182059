import axios from "axios";
const API_URL = "/api/notes/";

// Get ticket notes
const getNotes = async (ticketId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(API_URL + ticketId, config);
  return response.data;
};

// Create ticket note
const createNote = async (noteData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    API_URL,
    noteData,
    config
  );
  return response.data;
};

const notesService = {
  getNotes,
  createNote
};

export default notesService;
