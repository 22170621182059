import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import permissionsService from "./permissionsService";
import errorManager from "../../../utils/errorManager";

const initialState = {
  permissions: [],
  permission: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get users
export const getPermissions = createAsyncThunk(
  "permissions/getAll",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await permissionsService.getPermissions(token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

// Get user
export const getPermission = createAsyncThunk(
  "permissions/getOne",
  async (permissionId, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await permissionsService.getPermission(permissionId, token);
    } catch (error) {
      return thunkAPI.rejectWithValue(errorManager(error));
    }
  }
);

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      // get users
      .addCase(getPermissions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permissions = action.payload;
      })
      .addCase(getPermissions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // get user
      .addCase(getPermission.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPermission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.permission = action.payload;
      })
      .addCase(getPermission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
    ;
  },
});

export const { reset } = permissionSlice.actions;
export default permissionSlice.reducer;
