import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Badge } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { deleteRole, getRoles, updateRole, reset as roleReset, } from "../../../../app/slices/roles/rolesSlice";
import { getPermissions, reset as permissionReset } from "../../../../app/slices/permissions/permissionsSlice";
import Spinner from '../../../../components/Spinner';
import usePermissions from '../../../../hooks/usePermissions';
import CreateRole from "./create/CreateRole";

const Roles = () => {
  const dispatch = useDispatch();
  const { roles, isLoading, isError, message } = useSelector(state => state.roles);
  const { permissions } = useSelector(state => state.permissions);
  const canReadRoles = usePermissions(['roles:read']);
  const canCreateRoles = usePermissions(['roles:create']);
  const canUpdateRoles = usePermissions(['roles:update']);
  const canDeleteRoles = usePermissions(['roles:delete']);

  const [showModal, setShowModal] = useState(false);
  const [roleToEdit, setRoleToEdit] = useState(null);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    permissions: [],
    roleId: ''
  });

  useEffect(() => {
    if (canReadRoles) {
      dispatch(getRoles());
      dispatch(getPermissions())
    }

    return () => {
      if (canReadRoles) {
        dispatch(roleReset());
        dispatch(permissionReset());
      }
    };
  }, [dispatch, canReadRoles]);

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
  }, [isError, message]);

  const handleDelete = async (roleId) => {
    try {
      await dispatch(deleteRole(roleId));
      setShowModal(false);
      await dispatch(getRoles());
      toast.success('Role deleted');
    } catch (error) {
      toast.error('Error deleting user');
    }
  };

  const openEditModal = (_role) => {
    setRoleToEdit(_role);
    setRoleToDelete(null);
    const _formData = {
      name: _role.name,
      description: _role.description,
      permissions: _role.permissions,
      roleId: _role._id
    }
    setFormData(_formData);
    setShowModal(true);
  };

  const openDeleteModal = (_role) => {
    setRoleToDelete(_role);
    setRoleToEdit(null);
    setShowModal(true);
  };

  const handleEditSubmit = async () => {
    try {
      formData.permissions = formData.permissions.map((_perm) => _perm._id);
      await dispatch(updateRole(formData));
      setShowModal(false);
      await dispatch(getRoles());
      toast.success('Role edited');
    } catch (error) {
      toast.error('Error editing role');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePermissionChange = async (e) => {
    e.preventDefault();
    const permissionToFind = e.target.value;
    const roleHasPermission = formData.permissions.some(_p => _p._id === permissionToFind);

    if (roleHasPermission === false) {
      const permissionToAdd = permissions.find((permission) => permission._id === permissionToFind);
      formData.permissions = [...new Set([...formData.permissions, permissionToAdd])];
      setFormData(formData);
    } else {
      formData.permissions = formData.permissions.filter((_perm) => _perm._id !== permissionToFind);
      setFormData(formData);
    }

    await dispatch(getPermissions());
  }

  return (
    <>
      <h2 className='mb-5'>
        Role Management
        &nbsp;{ canCreateRoles && <CreateRole />}
      </h2>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th className="d-none d-lg-table-cell">Description</th>
            <th className="d-none d-lg-table-cell">Permissions</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="4"><Spinner /></td>
            </tr>
          ) : (
            roles.map((_role) => (
              <tr key={_role._id}>
                <td>{_role.name}</td>
                <td className="d-none d-lg-table-cell">{_role.description}</td>
                <td className="d-none d-lg-table-cell">{_role.permissions.map((_permission) => <Badge key={_permission._id} bg="dark">{_permission.name}</Badge>)}</td>
                <td>
                  {
                    canUpdateRoles && (
                          <Button
                              variant="primary"
                              size="sm"
                              onClick={() => openEditModal(_role)}
                              className="me-2"
                          >
                            Edit
                          </Button>
                      )
                  }
                  {
                    canDeleteRoles &&
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => openDeleteModal(_role)}
                    >
                      Delete
                    </Button>
                  }
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      {/* Edit and Delete Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{roleToEdit ? 'Edit Role' : 'Confirm Delete'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {roleToEdit && (
            <Form>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  // type="text"
                  // name="name"
                  // value={formData.name}
                  placeholder={formData.name}
                  disabled={true}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <Form.Group controlId="permissions">
                <Form.Label>Permissions</Form.Label>
                {permissions.map((_permission, index) => (
                    <Form.Check
                        id={_permission._id}
                        key={_permission._id}
                        type="checkbox"
                        label={_permission.description}
                        value={_permission._id}
                        checked={formData.permissions.some((_fdp) => _fdp._id === _permission._id)}
                        onChange={handlePermissionChange}
                    />
                ))}
              </Form.Group>
            </Form>
          )}
          { roleToDelete && (
            "Are you sure you want to delete this role? This action cannot be undone."
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          {roleToEdit && (
            <Button variant="primary" onClick={handleEditSubmit}>
              Save Changes
            </Button>
          )}
          { roleToDelete && (
            <Button variant="danger" onClick={() => handleDelete(roleToDelete._id)}>
              Delete
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Roles;
